import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/ng/bank-connect',
    name: 'Deposit',
    component: () => import(/* webpackChunkName: "connect" */ '../views/nigeria/Connect.vue'),
  },
  {
    path: '/ng/bank-checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */'../views/nigeria/Checkout.vue'),
  },
  {
    path: '/card/flwv',
    name: 'Flutterwave',
    component: () => import(/* webpackChunkName: "flutterwave" */'../views/Flutterwave.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
