<template>
  <div class="loader" :class="{ fullscreen }">
    <img src="../assets/images/loader.svg" height="48" />
  </div>
</template>

<script>
export default {
  props: {
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader.fullscreen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9;
  background: var(--background-color);
}
</style>