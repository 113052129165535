<template>
  <div class="app">
    <loader v-if="isLoading" fullscreen />
    <div class="modal">
      <router-view />
    </div>
  </div>
</template>

<script>
import Loader from "./components/Loader.vue";

export default {
  components: { Loader },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$root.$on("showLoader", (isLoading) => (this.isLoading = isLoading));
    if (Transfers) {
      const environment = process.env.NODE_ENV;
      Transfers.init({
        siteName: "Accrue",
        siteUrl: "https://useaccrue.com",
        siteLogo:
          "https://res.cloudinary.com/rockets-cdn/image/upload/v1629964715/accrue-logo_rkhthd.png",
        publicKey:
          environment === "production"
            ? "pk_live_UgIgIkzUlxMbQopBBELVMEpZHKTcmHsz"
            : "pk_live_hquDFtpvXyiARvvMbLCnxPKWOCLwVUbR",
        environment,
      });
    }
  },
};
</script>

<style lang="scss">
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .modal {
    text-align: center;
    width: 30%;
  }

  p {
    font-size: 1.6rem;
  }
}

iframe {
  border: none;
}

@media screen and (max-width: 480px) {
  .app {
    .modal {
      width: 100vw;
    }
  }
}
</style>
