import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/styles.css';
import RSST from './mixins/rsst';

Vue.config.productionTip = false;

Vue.mixin(RSST);
Vue.prototype.$showLoader = function (show) {
  this.$root.$emit('showLoader', show);
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
